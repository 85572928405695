import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Loadable from '@loadable/component';

import logo from './logo.svg';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

const Routes = Loadable(() => import('./components/Routers/RouterComponent'), {
  fallback: <div>Loading...</div>,
})

function App() {
  return (
    <BrowserRouter>
        <div className="App">
            <Routes />
        </div>
     </BrowserRouter>
  );
}

export default App;
